import React,{useEffect,useState,useRef} from "react";
import { getValue } from '@syncfusion/ej2-base';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Resize,
  Sort,
  ContextMenu,
  Filter,
  Page,
  ExcelExport,
  PdfExport,
  Edit,
  Inject,
  Toolbar,
} from "@syncfusion/ej2-react-grids";
import { DialogComponent,DialogUtility } from '@syncfusion/ej2-react-popups';
import { DropDownListComponent  } from '@syncfusion/ej2-react-dropdowns';
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";

import { ordersData, ordersGrid,modelOrdersGrid,orderItemsGrid,GetPadItemsData,FixOrders } from "../data/orders";
import { Header } from "../components";
import { useStateContext } from "../contexts/ContextProvider";
import { toCurrency } from "../tools/currency";

const Orders = () => {
  const gridcomp = useRef();
  const gridModal = useRef();
  const compBox = useRef();

  const {currentMode} = useStateContext();
  const [Orders, setOrders] = useState([]);
  const [SelectedOrders, setSelectedOrders] = useState([]);
  const [OrderItems, setOrderItems] = useState([]);
  const [BOrders, setBOrders] = useState([]);

  const [MultipleOn, setMultipleOn] = useState(false);
  
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [padItemsData, setPadItemsData] = useState([]);




  //const [selectedPAD, setSelectedPAD] = useState(['']);

  //let PadFields = { text: "EntityButtonName", value: "IID" };

  //modal
  const [visibilityDetail, setDialogVisibilityDetail] = useState(false);
  const [visibilityOrder, setDialogVisibilityOrder] = useState(false);
  
  const dialogClose = ()=> {
    setDialogVisibilityDetail(false);
    setDialogVisibilityOrder(false);
  }

  const [info, setInfo] = useState({Total:0,Vat:0,WVat:0,NVat:0,Card:0,Cash:0});
  
  const bgcolor = currentMode === "Dark" ? "bg-gray-700" : "bg-white";

  //const toolbarOptions = ['Edit', 'Delete', 'Update', 'Cancel','Search']; toolbar={toolbarOptions}

  const eSettings = {allowEditOnDblClick: false};
  const sSettings = { type: 'Single', enableSimpleMultiRowSelection: false};

  const actionBegin = (args) => {
    if (args.requestType === 'save') {
      //alert(args.rowData.OrderID);
    }
  };

  const rowSelected = (args) => {  
    //alert(args.data.UserName)
    setSelectedProduct(args.data);
  }
  const ShowAll=()=>{
    document.getElementById("rdPaid").checked = false;
    document.getElementById("rdUnpaid").checked = false;
    
    document.getElementById("rdCash").checked = false;
    document.getElementById("rdCard").checked = false;

    //console.log(gStatus);
    setOrders(BOrders);
    setInfo(calculateTotals(BOrders));
  }

  const ShowFiltered=()=>{
    
    let bOrders = BOrders
    if(document.getElementById("rdPaid").checked === true){      
      bOrders = bOrders.filter((item) => item.Status > 2);
    }else if(document.getElementById("rdUnpaid").checked === true){     
      bOrders = bOrders.filter((item) => item.Status < 3);
    }else if(document.getElementById("rdCash").checked === true){  
      bOrders = bOrders.filter((item) => item.Payment === 1);
    }else if(document.getElementById("rdCard").checked === true){
      bOrders = bOrders.filter((item) => item.Payment === 3);
    }
    
    setOrders(bOrders);
    setInfo(calculateTotals(bOrders));
  }
  const calculateTotals=(dataorder)=>{
    let WVat = dataorder.reduce((acc,item) => acc + parseFloat(item.WVat),0);     
    let NVat = dataorder.reduce((acc,item) => acc + parseFloat(item.NVat),0);         
    let vat = dataorder.reduce((acc,item) => acc + parseFloat(item.vat),0);
    let total = dataorder.reduce((acc,item) => acc + parseFloat(item.total),0);
    let cash = dataorder.reduce((acc,item) => acc + ((item.Payment===1?parseFloat(item.NVat)+parseFloat(item.WVat):0)),0);
    let card = dataorder.reduce((acc,item) => acc + ((item.Payment===3?parseFloat(item.NVat)+parseFloat(item.WVat):0)),0);

    let myInfo ={};

    
    myInfo.Vat = toCurrency(vat);
    myInfo.WVat = toCurrency(WVat);
    myInfo.NVat = toCurrency(NVat);
    myInfo.Cash = toCurrency(cash);
    myInfo.Card = toCurrency(card);
    myInfo.Total = toCurrency(total);
    
    return myInfo;
  }

  const getOrders = (fn=(data)=>{}) => {
      
    GetPadItemsData((pdata)=>setPadItemsData(pdata)); //get PAD Items
      
      setOrders([]);//clean the grid before loading      
      ordersData((dataorder)=>{ 
        setBOrders(dataorder);// leading data to Backup
        setOrders(dataorder);// leading data to the grid        
        setInfo(calculateTotals(dataorder));
        fn(dataorder);
      }); 
  }


  const Details=()=>{
    if(MultipleOn===false){
      if(gridcomp.current.selectedRowIndex>=0)
      {
        var btnSendEmail = document.getElementById('sendEmail')
        if(btnSendEmail!==undefined && btnSendEmail.getAttribute('listener') !== 'true') {
          btnSendEmail.addEventListener('click',()=>{alert("Still in development!")});
          btnSendEmail.setAttribute('listener', 'true');
        }

        setDialogVisibilityDetail(true);
        setOrderItems(selectedProduct.sub);
        document.getElementById('orderitemtotal').innerText='Total : ' + toCurrency(selectedProduct.total);
        document.getElementById('orderitemvat').innerText='VAT : ' + toCurrency(selectedProduct.vat);
      }    
    }
    else{
      if(gridcomp.current.selectedRowIndex>=0)
        {
        setSelectedOrders(gridcomp.current.getSelectedRecords());
        setDialogVisibilityOrder(true);

        var btnFixOrder = document.getElementById('changeModalOrder')
        //btnFixOrder.addEventListener('click',ChangeOrder); 
        if(btnFixOrder!==undefined && btnFixOrder.getAttribute('listener') !== 'true') {
          btnFixOrder.addEventListener('click',()=>{
            if(compBox.current.itemData === null){
              alert('Select an Item');
              return;
            }
            
            let idList = gridModal.current.dataSource.map((item) => item.IID);
            FixOrders(compBox.current.itemData.value,idList,FixedOrders);
          });
          btnFixOrder.setAttribute('listener', 'true');
        }
      }
    }
    
  }

  
  const FixedOrders=(result)=>{
    //dialogClose();
    //ShowToast('Orders Fixed');
    getOrders((data)=>{
      let idList = gridModal.current.dataSource.map((item) => item.IID);
      setSelectedOrders(data.filter((item) => idList.includes(item.IID)));
    });
    console.log('Orders Fixed');
    //gridcomp.dataSource = [];
  }

  const makeSimpleOrder = () => {
    if(MultipleOn===true){
      setMultipleOn(false);
      gridcomp.current.selectedSettings= { type: 'Single', enableSimpleMultiRowSelection: false };
      gridcomp.current.clearSelection();
    }else{ 
      setMultipleOn(true);
      gridcomp.current.selectionSettings= { type: 'Multiple',  enableSimpleMultiRowSelection: true};      

    }/**/
  }
 

  useEffect(() => {  
    //console.log('test');
    if(MultipleOn===false){

    }else{
      let modalInfo = calculateTotals(SelectedOrders);        
      document.getElementById('ordermodaltotal').innerText='Total : ' + modalInfo.Total;
      document.getElementById('ordermodalvat').innerText='VAT : ' + modalInfo.Vat;

      

    }
  },[SelectedOrders,MultipleOn]);



  

  return (
    <div className={"m-2 md:m-5 mt-24 p-2  rounded-2xl " + bgcolor} >
      <Header title="Orders" />   
      <div className="grid grid-cols-5 gap-2">       
        <div className="col-span-5">
        <div className="e-btn-group grid grid-cols-2 lg:grid-cols-4">
          <button onClick={getOrders}
                className="e-btn bg-indigo-300 hover:bg-indigo-200 shadow-primary-1 p-2 font-medium uppercase rounded" 
                >LOAD ORDER</button>
          <button onClick={ShowAll}
              className="e-btn bg-orange-300 hover:bg-orange-200 drop-shadow-md p-2 font-medium uppercase rounded" 
                >show all</button>
        </div>
          <div id="gStatus" className="e-btn-group grid grid-cols-2 lg:grid-cols-4">
              
              <label className="e-btn bg-green-300 drop-shadow-md p-2 font-medium uppercase rounded has-[:checked]:bg-lime-300">
              <input type="radio" id="rdPaid" name="gFilter" value="paid" onChange={ShowFiltered}   />
              PAID</label>

              
              <label className="e-btn bg-green-300 drop-shadow-md p-2 font-medium uppercase rounded has-[:checked]:bg-lime-300">
              <input type="radio" id="rdUnpaid" name="gFilter" value="unpaid" onChange={ShowFiltered}  />
              UNPAID</label>

              
              <label className="e-btn bg-green-300 drop-shadow-md p-2 font-medium uppercase rounded has-[:checked]:bg-lime-300" >
              <input type="radio" id="rdCard" name="gFilter" value="card" onChange={ShowFiltered}  />
              CARD</label>
              
              
              <label className="e-btn bg-green-300 drop-shadow-md p-2 font-medium uppercase rounded has-[:checked]:bg-lime-300">
              <input type="radio" id="rdCash" name="gFilter" value="cash" onChange={ShowFiltered}   />
              CASH</label>
          </div>
          <div className="e-btn-group grid grid-cols-2 lg:grid-cols-4">
            <button onClick={Details}
                className="e-btn bg-indigo-300 hover:bg-indigo-200 shadow-primary-1 p-2 font-medium uppercase rounded" 
            >Order Details</button>
            <button onClick={makeSimpleOrder}
                className="e-btn bg-yellow-300 hover:bg-indigo-200 shadow-primary-1 p-2 font-medium uppercase rounded"
            > Order</button>
          </div>
        </div>
        <div className="col-span-5 grid grid-cols-2 gap-0.5">
          <label className="e-label text-sm text-right bg-zinc-50 pr-2 drop-shadow-md">Card : <b>{info.Card}</b></label>
          <label className="e-label text-sm text-right bg-zinc-50 pr-2 drop-shadow-md">Cash : <b>{info.Cash}</b></label>   
          <label className="e-label text-sm text-right bg-zinc-50 pr-2 drop-shadow-md">W/ VAT : <b>{info.WVat}</b></label>
          <label className="e-label text-sm text-right bg-zinc-50 pr-2 drop-shadow-md">VAT Total  : <b>{info.Vat}</b></label>     
          <label className="e-label text-sm text-right bg-zinc-50 pr-2 drop-shadow-md">W/No VAT : <b>{info.NVat}</b></label>
          <label className="e-label text-sm text-right bg-zinc-50 pr-2 drop-shadow-md">Total : <b>{info.Total}</b></label>   
        </div>
      </div>  
      
      <GridComponent  ref={gridcomp} allowPaging allowSorting   selectionSettings={sSettings}  created={getOrders} dataSource={Orders}
                                            actionBegin={actionBegin.bind(this)}  rowSelected={rowSelected}  > 
        <ColumnsDirective>
          {ordersGrid.map((item, index) => (
            <ColumnDirective key={index} {...item}  />
          ))}
        </ColumnsDirective>
        <Inject services={[Resize, Sort, ContextMenu, Filter, Page, ExcelExport, Edit, PdfExport,Toolbar]} />
      </GridComponent>

      <DialogComponent isModal={true}  id="modalDetails" showCloseIcon={true} visible={visibilityDetail} close={dialogClose} header="Order Details" 
      cssClass="e-fixed border-8 border-zing-500" width='600px' >
        <div >
          <div className="grid grid-cols-4 gap-2">
            <input type="text" id="txtEmail" placeholder="Email address to send as an Invoice"
              className="block flex-1 border-1 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 col-span-3 lg:col-span-2 " />
            <button id="sendEmail"
                className="e-btn bg-sky-300 hover:bg-indigo-200 shadow-primary-1 p-2 font-medium uppercase rounded" 
                >Send Email</button>
              <div className="col-span-4 lg:col-span-1">
                <div id="orderitemtotal" className="e-label text-sm text-right bg-zinc-50 pr-2 drop-shadow-md"></div> 
                <div id="orderitemvat" className="e-label text-sm text-right bg-zinc-50 pr-2 drop-shadow-md"></div> 
            </div>
          </div>
          <GridComponent allowPaging allowSorting  editSettings={eSettings} dataSource={OrderItems}>
          <ColumnsDirective>
          {orderItemsGrid.map((item, index) => (
            <ColumnDirective key={index} {...item}  />
          ))}
        </ColumnsDirective>
        <Inject services={[Resize, Sort, ContextMenu, Filter, Page, ExcelExport, Edit, PdfExport,Toolbar]} />
      </GridComponent>

        
        
        
        </div>
      </DialogComponent>

      <DialogComponent isModal={true}  id="modalOrders" showCloseIcon={true} visible={visibilityOrder} close={dialogClose} header="Orders" 
      cssClass="e-fixed border-8 border-zing-500" width='600px' >
        <div >
          <div className="grid grid-cols-4 gap-2">
            <DropDownListComponent id="compBoxId" ref={compBox}   placeholder="Select an Item"  dataSource={padItemsData}    />
            <button  id="changeModalOrder" 
                className="e-btn bg-sky-300 hover:bg-indigo-200 shadow-primary-1 p-2 font-medium uppercase rounded" 
                >Fix it </button >
              <div className="col-span-4 lg:col-span-2">
                <div id="ordermodaltotal" className="e-label text-sm text-right bg-zinc-50 pr-2 drop-shadow-md"></div> 
                <div id="ordermodalvat" className="e-label text-sm text-right bg-zinc-50 pr-2 drop-shadow-md"></div> 
            </div>
          </div>
          <GridComponent ref={gridModal} allowPaging allowSorting  editSettings={eSettings} dataSource={SelectedOrders}>
          <ColumnsDirective>
          {modelOrdersGrid.map((item, index) => (
            <ColumnDirective key={index} {...item}  />
          ))}
        </ColumnsDirective>
        <Inject services={[Resize, Sort, ContextMenu, Filter, Page, ExcelExport, Edit, PdfExport,Toolbar]} />
      </GridComponent>
        </div>
      </DialogComponent>
    </div>
  );
};

export default Orders;
