import React,{useEffect,useState,useRef} from "react";
import { useNavigate } from "react-router-dom";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Resize,
  Sort,
  Filter,
  Page,
  Edit,
  Inject,
  Toolbar,
} from "@syncfusion/ej2-react-grids";
import { DialogUtility } from '@syncfusion/ej2-react-popups';
import { DropDownListComponent  } from '@syncfusion/ej2-react-dropdowns';
import { Header } from "../../components";
import { useStateContext } from "../../contexts/ContextProvider";

import { cataloguesData,entitiesData,entityGrid, entityDelete } from "../../data/posmenu";
import { TillToPi,PiToTill } from "../../data/posMenuSync";
import { SimpSpiner,ShowToast } from "../../tools/extras";
import "./../CSS/POSMenu.css"




const POSEntity = () => {
  const gridcomp = useRef();
  const compBox = useRef();
  const confirmObj = useRef();
  const navigate = useNavigate();

  const [CatalogueData, setCatalogue] = useState([]);
  const [EntityData, setEntity] = useState([]);
  
  const [selectedCatalogue, setSelectedCatalogue] = useState('');

  const { currentMode } = useStateContext();
  const bgcolor = currentMode === "Dark" ? "bg-gray-700" : "bg-white";

  
  let CatalogueFields = { text: "CatalogName", value: "IID" };

  let getCatalogues = (fn=()=>{}) => { 
    cataloguesData((dataorder)=>{ 
      setCatalogue(dataorder);// leading data to the ListBox
      setSelectedCatalogue(dataorder[0].IID);
      getEntities(dataorder[0].IID);
      fn();
    });     
  }
  let getEntities= (iid) => { 
    entitiesData(iid,(dataorder)=>{ 
      setEntity(dataorder);// leading data to the ListBox
    });     
  }

  const showSelectedCatalogue=(arg)=>{
    let catalogueIID=arg.target.value
    getEntities(catalogueIID);
  }


  const entityChange=()=>{ 
    if(gridcomp.current.selectedRowIndex>=0){      
      let selectedRow = (gridcomp.current.getSelectedRecords())[0]    
      navigate("/posmenu/"+selectedCatalogue+"/"+selectedRow.IID);
    }else{
      ShowToast({content:`Please select an entity first`})
    }
  }

  const entityNew=()=>{
    navigate("/posmenu/"+selectedCatalogue+"/new");
  }

  const entityDel=()=>{    
    if(gridcomp.current.selectedRowIndex>=0){  
      let selectedRow = (gridcomp.current.getSelectedRecords())[0]   
      entityDelete(selectedRow.IID,()=>{
        getEntities(selectedCatalogue);
        confirmObj.current.hide();  
        ShowToast({content:`It's deleted!`,type:'success'});
      })
    }    
  }
  
  const DeleteConfirm = ()=>{
    if(gridcomp.current.selectedRowIndex>=0){
      confirmObj.current = DialogUtility.confirm({      
        //cancelButton: { text: 'Cancel', click: ()=>{console.log("gave up")} },
        closeOnEscape: false,
        content: "When you delete it, there is no option to get it back. Please think twice before deleting anything!",
        okButton: { text: 'DELETE IT', click: entityDel.bind(this) },
        showCloseIcon: true,
        title: 'Do you really want to delete it?',
      }); 
    }else{
      ShowToast({content:`Please select an item first`})
    }
  }


  useEffect(() => {
    getCatalogues();
    
  }, []);

  

/*menu Sync Start*/
const getMenu=()=>{
  confirmObj.current = DialogUtility.confirm({      
    cancelButton: { text: 'Cancel', icon: 'e-icons e-close'  },
    closeOnEscape: false,
    content: SimpSpiner("When you get the menu, all items will be same as the till! there is no option to get it back. Please think twice before getting anything"),
    okButton: { text: 'GET FROM TILL', click: getMenuConfirm.bind(this),icon: 'e-icons e-check' },
    showCloseIcon: true,
    title: 'Do you really want to get it?',
  }); //
  }
const getMenuConfirm=()=>{
  document.getElementById('SimpSpiner').style.visibility='visible';
  TillToPi(()=>{
  getCatalogues(()=>{
    confirmObj.current.hide();
    document.getElementById('SimpSpiner').style.visibility='hidden';
    });
  });
  }
const SaveMenu=()=>{
  confirmObj.current = DialogUtility.confirm({      
    cancelButton: { text: 'Cancel', icon: 'e-icons e-close'  },
    closeOnEscape: false,
    content: SimpSpiner("When you save it to till, all items on the till be same as this menu. there is no option to get it back. Please think twice before saving anything!"),
    okButton: { text: 'SAVE TO TILL', click: SaveMenuConfirm.bind(this), icon: 'e-icons e-check' },
    showCloseIcon: true,
    title: 'Do you really want to save it?',
  });   
  }
const SaveMenuConfirm=()=>{
  document.getElementById('SimpSpiner').style.visibility='visible';
  PiToTill(()=>{
    getCatalogues(()=>{
      confirmObj.current.hide();
      document.getElementById('SimpSpiner').style.visibility='hidden';
      });
    });
  }   
/*menu Sync End*/



    return(
      <div className={"m-2 md:mx-5 mt-2 p-2 md:p-5 rounded-2xl " + bgcolor} >
      <Header title="POS Menu" />   
        <div>
          <div className="grid grid-cols-4">
            <div className="col-cols-1 grid  grid-cols-1 lg:grid-cols-2">
              <button onClick={getMenu}
                className="e-btn bg-indigo-300 shadow-primary-1 p-2 font-medium uppercase rounded" 
                >Get Menu</button>
              <button onClick={SaveMenu}
              className="e-btn bg-orange-300 drop-shadow-md p-2 font-medium uppercase rounded" 
                >Send Menu</button>
              <DropDownListComponent  id="comboelement" ref={compBox} placeholder="Select a Menu"  dataSource={CatalogueData} fields={CatalogueFields}
                                                                      onChange={showSelectedCatalogue} value={selectedCatalogue}/>
            </div>
            <div className="col-span-3 grid grid-cols-5">
              <button className="e-btn bg-yellow-300 drop-shadow-md p-2 font-medium uppercase rounded col-start-5" 
                onClick={()=>{ShowToast({content:` its not ready yet`,type:'warning'});}} >sort</button>
              <button className="e-btn bg-green-300 drop-shadow-md p-2 font-medium uppercase rounded  col-start-3" 
                onClick={entityNew} >Add</button>
              <button className="e-btn bg-sky-300 drop-shadow-md p-2 font-medium uppercase rounded" 
                onClick={entityChange} >Edit</button>
              <button className="e-btn bg-red-300 drop-shadow-md p-2 font-medium uppercase rounded" 
                onClick={DeleteConfirm} >Delete</button>
            </div>
          </div>
          <hr />
          <div>
            <GridComponent ref={gridcomp} allowPaging allowSorting dataSource={EntityData}  >
              <ColumnsDirective>
                {entityGrid.map((item, index) => (
                  <ColumnDirective key={index} {...item}  />
                ))}
              </ColumnsDirective>
              <Inject services={[Resize, Sort, Filter, Page, Edit, Toolbar]} />
            </GridComponent>
          </div>
        </div>
    </div>
    )
  }
  
  export default POSEntity;
  